import React from 'react';
import * as yup from 'yup';
import {
  useDeleteDeviceEmail,
  useDeleteDeviceImei,
  useDeleteDeviceMsisdn,
  useDevice,
  useUpdateDeviceEmail,
  useUpdateDeviceImei,
  useUpdateDeviceMsisdn,
  useUpdateDeviceName,
} from '../../../hooks';
import { DeviceInterface } from '../../../services/api';
import { ApiError } from '../../../state';
import { EditFieldDialogProps } from '../../EditFieldDialog/EditFieldDialog';

type ProfileEditFieldDialogProps = Omit<EditFieldDialogProps, 'onClose'>;

export const useProfile = (id: bigint) => {
  const [deviceId] = React.useState<bigint>(id);
  const [device, setDevice] = React.useState<DeviceInterface | undefined>(
    undefined,
  );
  const [error, setError] = React.useState<ApiError | null>(null);
  const [editFieldDialogProps, setEditFieldDialogProps] =
    React.useState<ProfileEditFieldDialogProps>({
      open: false,
      initialValue: '',
      validationSchema: yup.object({
        value: yup.string().trim().required(),
      }),
      onSubmitClicked: () => {},
    });
  const [
    { device: getDeviceResult, loading, error: getDeviceError },
    getDevice,
  ] = useDevice();
  const [, updateName] = useUpdateDeviceName();
  const [, updateEmail] = useUpdateDeviceEmail();
  const [, deleteEmail] = useDeleteDeviceEmail();
  const [, updateImei] = useUpdateDeviceImei();
  const [, deleteImei] = useDeleteDeviceImei();
  const [, updateMsisdn] = useUpdateDeviceMsisdn();
  const [, deleteMsisdn] = useDeleteDeviceMsisdn();

  React.useEffect(() => {
    getDevice(deviceId);
  }, [deviceId]);

  React.useEffect(() => {
    if (getDeviceResult) {
      setDevice(getDeviceResult);
      setEditFieldDialogProps({
        ...editFieldDialogProps,
        open: false,
      });
    }
  }, [getDeviceResult]);

  React.useEffect(() => {
    setError(getDeviceError);
  }, [getDeviceError]);

  return {
    device,
    loading,
    error,
    editFieldDialogProps,
    setEditFieldDialogProps,
    updateName,
    updateEmail,
    deleteEmail,
    updateImei,
    deleteImei,
    updateMsisdn,
    deleteMsisdn,
  };
};
