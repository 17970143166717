import React from 'react';
import { AuthenticatedSite } from '../constants';
import { useLocalStorage } from '../hooks';

export interface AuthenticationValues {
  site: AuthenticatedSite | null;
  setSite: (site: AuthenticatedSite | null) => void;
}

const initialAuthenticationValues: AuthenticationValues = {
  site: null,
  setSite: () => {},
};

export const AuthenticationContext = React.createContext<AuthenticationValues>(
  initialAuthenticationValues,
);

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [site, setSite] = useLocalStorage<AuthenticatedSite | null>(
    'selectedSite',
    null,
  );

  return (
    <AuthenticationContext.Provider value={{ site, setSite }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
