import { Skeleton, Typography, TypographyProps } from '@mui/joy';
import { ReactElement } from 'react';

interface TypographySkeletonProps extends TypographyProps {
  loading: boolean;
  skeleton: string;
}

function TypographySkeleton(props: TypographySkeletonProps): ReactElement {
  const { loading, skeleton, children, ...other } = props;
  return (
    <Typography {...other}>
      <Skeleton loading={loading}>{loading ? skeleton : children}</Skeleton>
    </Typography>
  );
}

export default TypographySkeleton;
