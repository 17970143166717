import { Add, Warning } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
} from '@mui/joy';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AppImages } from '../../constants';
import {
  formatMsisdn,
  getTimeDifference,
  TimeDifferenceModifier,
} from '../../helpers';
import ContactLink from '../ContactLink/ContactLink';
import DeviceDetailField from './DeviceDetailField';
import EditFieldDialog from '../EditFieldDialog/EditFieldDialog';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';
import { useProfile } from './hooks';
import DeviceDetailMenu from './DeviceDetailMenu';
import { IMEI_MAXIMUM_LENGTH, IMEI_MINIMUM_LENGTH } from '../../services/api';

function getJoinedString(created: Date) {
  const now = new Date();
  const days = getTimeDifference(created, now, TimeDifferenceModifier.DAYS);
  if (days <= 1) {
    return 'Joined today';
  } else if (days == 2) {
    return 'Joined yesterday';
  } else {
    return `Joined ${days} days ago`;
  }
}

function DeviceDetail(): ReactElement {
  const { id } = useParams();
  const deviceId = BigInt(id ?? 0);

  const {
    device,
    loading,
    error,
    editFieldDialogProps,
    setEditFieldDialogProps,
    updateName,
    updateEmail,
    deleteEmail,
    updateImei,
    deleteImei,
    updateMsisdn,
    deleteMsisdn,
  } = useProfile(deviceId);

  const phoneNumber = formatMsisdn(device?.msisdn);
  const avatarSrc = device?.img_url || AppImages.DefaultAvatarSrc;

  const editName = () =>
    setEditFieldDialogProps({
      open: true,
      label: 'Name',
      type: 'text',
      initialValue: device?.name ?? '',
      isUpdate: Boolean(device?.name),
      validationSchema: yup.object({
        value: yup.string().trim().required(),
      }),
      onSubmitClicked: ({ value }) => {
        updateName(deviceId, value);
      },
    });

  const editEmail = () =>
    setEditFieldDialogProps({
      open: true,
      label: 'Email',
      type: 'email',
      initialValue: device?.email || '',
      isUpdate: Boolean(device?.email),
      validationSchema: yup.object({
        value: yup.string().trim().email().required(),
      }),
      onSubmitClicked: ({ value }) => {
        updateEmail(deviceId, value);
      },
    });

  const editMsisdn = () =>
    setEditFieldDialogProps({
      open: true,
      label: 'MSISDN',
      type: 'tel',
      initialValue: device?.msisdn || '',
      isUpdate: Boolean(device?.msisdn),
      validationSchema: yup.object({
        value: yup.string().trim().required(),
      }),
      onSubmitClicked: ({ value }) => {
        updateMsisdn(deviceId, value);
      },
    });

  const editImei = () =>
    setEditFieldDialogProps({
      open: true,
      label: 'IIMEI',
      type: 'text',
      initialValue: device?.imei || '',
      isUpdate: Boolean(device?.imei),
      validationSchema: yup.object({
        value: yup
          .string()
          .matches(/^[0-9]+$/)
          .min(IMEI_MINIMUM_LENGTH)
          .max(IMEI_MAXIMUM_LENGTH)
          .trim()
          .required(),
      }),
      onSubmitClicked: ({ value }) => {
        updateImei(deviceId, value);
      },
    });

  return (
    <React.Fragment>
      {error && !editFieldDialogProps.open && (
        <Alert color="danger" startDecorator={<Warning />}>
          {error.message}
        </Alert>
      )}
      <Card
        sx={{
          width: 300,
          maxWidth: '100%',
          mx: 'auto',
        }}
      >
        <CardContent
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, minmax(80px, 1fr))',
            gap: 1.5,
          }}
        >
          <Box display="flex" justifyContent="end">
            <DeviceDetailMenu editName={editName} {...device} />
          </Box>
          <Stack spacing={0.5} alignItems="center" mt={-4}>
            <Avatar src={avatarSrc} sx={{ '--Avatar-size': '6rem' }} />
            {(loading || device?.name) && (
              <TypographySkeleton
                level="title-lg"
                loading={loading}
                skeleton="Device Name"
              >
                {device?.name}
              </TypographySkeleton>
            )}
            <TypographySkeleton loading={loading} skeleton="Join 365 days ago">
              {device && getJoinedString(new Date(device.created_at))}
            </TypographySkeleton>
            {!loading && !device?.name && (
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={editName}
              >
                Add Name
              </Button>
            )}
          </Stack>
          <Divider inset="none" />
          <Stack spacing={2}>
            <DeviceDetailField
              canCopy
              loading={loading}
              name="ID"
              skeleton="000000"
              content={`${device?.id}`}
            />
            <DeviceDetailField
              canAdd
              canEdit
              canDelete
              canCopy
              loading={loading}
              name="Email"
              skeleton="joy@mui.com"
              content={
                device?.email && (
                  <ContactLink type="mailto" text={device.email} />
                )
              }
              copyText={device?.email}
              onDeleteClick={() => deleteEmail(deviceId)}
              onEditClick={editEmail}
              onAddClick={editEmail}
            />
            <DeviceDetailField
              canAdd
              canEdit
              canDelete
              canCopy
              loading={loading}
              name="Phone Number"
              skeleton="+1 (800) 800-8000"
              content={
                phoneNumber && <ContactLink type="tel" text={phoneNumber} />
              }
              copyText={phoneNumber}
              onDeleteClick={() => deleteMsisdn(deviceId)}
              onEditClick={editMsisdn}
              onAddClick={editMsisdn}
            />
            <DeviceDetailField
              canAdd
              canEdit
              canDelete
              canCopy
              loading={loading}
              name="IMEI"
              skeleton="527464750498356"
              content={device?.imei}
              copyText={device?.imei}
              onDeleteClick={() => deleteImei(deviceId)}
              onEditClick={editImei}
              onAddClick={editImei}
            />
          </Stack>
        </CardContent>
      </Card>
      <EditFieldDialog
        loading={loading}
        onClose={() =>
          setEditFieldDialogProps({ ...editFieldDialogProps, open: false })
        }
        error={editFieldDialogProps.open && error ? error.message : undefined}
        {...editFieldDialogProps}
      />
    </React.Fragment>
  );
}

export default DeviceDetail;
