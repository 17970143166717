import { AxiosBasicCredentials } from 'axios';
import { Dispatch } from 'react';
import { getApiError } from '.';
import { AppServices } from '../../constants';
import { DeviceInterface } from '../../services/api';
import { HttpMethod } from '../../services/api/enums';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

const basePath = '/device';

interface CreateDeviceActionOptions<T> {
  method: HttpMethod;
  url: string;
  path?: string;
  payload?: T;
  auth?: AxiosBasicCredentials;
}

export function createDeviceAction<T>(options: CreateDeviceActionOptions<T>) {
  const { method, url, path = '', payload, auth } = options;
  return async (dispatch: Dispatch<ApiAction>) => {
    dispatch({ type: ActionType.DEVICE_REQUEST_STARTED });
    try {
      const data = await AppServices.Api.request<DeviceInterface, T>(
        method,
        url,
        `${basePath}${path}`,
        {},
        auth,
        payload,
      );
      dispatch({
        type: ActionType.DEVICE_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.DEVICE_REQUEST_ERROR,
        payload: getApiError(error),
      });
    }
  };
}

export function getAuthenticatedDevice(
  url: string,
  auth: AxiosBasicCredentials,
) {
  return createDeviceAction({ method: HttpMethod.GET, url, auth });
}

export function getDevice(url: string, id: bigint) {
  return createDeviceAction({ method: HttpMethod.GET, url, path: `/${id}` });
}

export function updateName(url: string, id: bigint, name: string) {
  return createDeviceAction({
    method: HttpMethod.PATCH,
    url,
    path: `/${id}/name`,
    payload: { name },
  });
}

export function updateEmail(url: string, id: bigint, email: string) {
  return createDeviceAction({
    method: HttpMethod.PATCH,
    url,
    path: `/${id}/email`,
    payload: { email },
  });
}

export function deleteEmail(url: string, id: bigint) {
  return createDeviceAction({
    method: HttpMethod.DELETE,
    url,
    path: `/${id}/email`,
  });
}

export function updateImei(url: string, id: bigint, imei: string) {
  return createDeviceAction({
    method: HttpMethod.PATCH,
    url,
    path: `/${id}/imei`,
    payload: { imei },
  });
}

export function deleteImei(url: string, id: bigint) {
  return createDeviceAction({
    method: HttpMethod.DELETE,
    url,
    path: `/${id}/imei`,
  });
}

export function updateMsisdn(url: string, id: bigint, msisdn: string) {
  return createDeviceAction({
    method: HttpMethod.PATCH,
    url,
    path: `/${id}/msisdn`,
    payload: { msisdn },
  });
}

export function deleteMsisdn(url: string, id: bigint) {
  return createDeviceAction({
    method: HttpMethod.DELETE,
    url,
    path: `/${id}/msisdn`,
  });
}
