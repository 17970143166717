import { Link, LinkProps } from '@mui/joy';
import { ReactElement } from 'react';

type LinkType = 'mailto' | 'tel';

interface ContactLinkProps extends LinkProps {
  text: string;
  type: LinkType;
}

function ContactLink(props: ContactLinkProps): ReactElement {
  const { text, type, ...other } = props;
  const contact = `${type}:${text}`;
  return (
    <Link href={contact} {...other}>
      {text}
    </Link>
  );
}

export default ContactLink;
