import { Warning } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Option,
  Stack,
  Typography,
} from '@mui/joy';
import { Form, Formik } from 'formik';
import { ReactElement } from 'react';
import * as yup from 'yup';
import { AppMetadata, SiteId } from '../../constants';
import { useLogin } from '../../hooks';
import { FormikInput, FormikSelect } from '../Formik';
import WeTraqIcon from '../Icons/WeTraqIcon';
import { LoginValues } from './types';

const validationSchema: yup.ObjectSchema<LoginValues> = yup.object({
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
  siteId: yup.mixed<SiteId>().oneOf(Object.values(SiteId)).required(),
});

function Login(): ReactElement {
  const [{ error, loading }, login] = useLogin();

  const onSubmit = ({ username, password, siteId }: LoginValues) => {
    const auth = { username, password };
    login(AppMetadata.getSiteById(siteId), auth);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        maxHeight: 'max-content',
        maxWidth: 'sm',
        mx: 'auto',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography level="title-lg" startDecorator={<WeTraqIcon />}>
          {AppMetadata.Name} Login
        </Typography>
        <Typography level="title-md">{AppMetadata.Version}</Typography>
      </Stack>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, minmax(80px, 1fr))',
          gap: 1.5,
        }}
      >
        {error && (
          <Alert color="danger" startDecorator={<Warning />}>
            {error.message}
          </Alert>
        )}

        <Formik
          initialValues={
            {
              username: '',
              password: '',
              siteId: AppMetadata.getSites()[0].id,
            } as LoginValues
          }
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Stack spacing={2}>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <FormikInput name="username" type="text" autoFocus required />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <FormikInput name="password" type="password" required />
                </FormControl>
                <FormControl>
                  <FormLabel>Site</FormLabel>
                  <FormikSelect name="siteId">
                    {AppMetadata.getSites().map(({ id, name }, index) => (
                      <Option key={index} value={id}>
                        {name}
                      </Option>
                    ))}
                  </FormikSelect>
                </FormControl>
                <CardActions>
                  <Button
                    disabled={!dirty || !isValid}
                    loading={loading}
                    type="submit"
                    variant="solid"
                    color="primary"
                  >
                    Login
                  </Button>
                </CardActions>
              </Stack>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

export default Login;
