import axios, {
  AxiosBasicCredentials,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import JSONBigLib from 'json-bigint';
import { HttpMethod } from './enums';
import { HttpParams } from './types';

const JSONBig = JSONBigLib({
  // Parses big int values to JS's BigInt type
  useNativeBigInt: true,
});

const transformRequest = (request: InternalAxiosRequestConfig) => {
  // Stringify outgoing requests
  request.transformRequest = [
    (data) => {
      // Only stringify plain JS objects. This excludes FormData, Files, Blobs, etc...
      if (
        typeof data === 'object' &&
        Object.getPrototypeOf(data) === Object.prototype
      )
        return JSONBig.stringify(data);
      return data;
    },
  ];
  // Parse incoming responses
  request.transformResponse = [
    (data) => {
      try {
        return JSONBig.parse(data);
      } catch (_e) {
        return data;
      }
    },
  ];
  return request;
};

export class ApiService {
  private readonly http: AxiosInstance;

  constructor(private readonly version: string = '1') {
    this.http = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    this.http.interceptors.request.use(transformRequest);
  }

  delete<T>(
    url: string,
    path: string,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
  ): Promise<T> {
    return this.request(HttpMethod.DELETE, url, path, params, auth);
  }

  get<T>(
    url: string,
    path: string,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
  ): Promise<T> {
    return this.request(HttpMethod.GET, url, path, params, auth);
  }

  patch<T, K>(
    url: string,
    path: string,
    data: K,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
  ): Promise<T> {
    return this.request<T, K>(HttpMethod.PATCH, url, path, params, auth, data);
  }

  post<T, K>(
    url: string,
    path: string,
    data: K,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
  ): Promise<T> {
    return this.request<T, K>(HttpMethod.POST, url, path, params, auth, data);
  }

  put<T, K>(
    url: string,
    path: string,
    data: K,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
  ): Promise<T> {
    return this.request<T, K>(HttpMethod.PUT, url, path, params, auth, data);
  }

  async request<T, K>(
    method: HttpMethod,
    baseUrl: string,
    path: string,
    params?: HttpParams,
    auth?: AxiosBasicCredentials,
    payload?: K,
  ): Promise<T> {
    const url = `${baseUrl}/v${this.version}${path}`;
    const config: AxiosRequestConfig = { auth, params };
    const { data } = await this.getResponse<T, K>(config, method, url, payload);
    return data;
  }

  getResponse<T, K>(
    config: AxiosRequestConfig,
    method: HttpMethod,
    url: string,
    data?: K,
  ): Promise<AxiosResponse<T, unknown>> {
    switch (method) {
      case HttpMethod.DELETE:
        return this.http.delete<T>(url, config);
      case HttpMethod.PATCH:
        return this.http.patch<T>(url, data, config);
      case HttpMethod.POST:
        return this.http.post<T>(url, data, config);
      case HttpMethod.PUT:
        return this.http.put<T>(url, data, config);
      default:
        return this.http.get<T>(url, config);
    }
  }
}
