import { Input, InputProps } from '@mui/joy';
import { useField } from 'formik';
import React, { ReactElement } from 'react';

export interface FormikInputProps extends Omit<InputProps, 'onChange'> {
  name: string;
}

export function FormikInput(props: FormikInputProps): ReactElement {
  const { name, ...other } = props;
  const [field] = useField(name);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    field.onChange({ target: { name, value } });
  };

  return <Input value={field.value} onChange={onChange} {...other} />;
}
