import { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import DeviceDetail from './components/DeviceDetail/DeviceDetail';
import DeviceTable from './components/DeviceTable/DeviceTable';
import NotFound from './components/Exceptions/NotFound';
import Login from './components/Login/Login';
import RouteAuthenticated from './components/RouteAuthenticated/RouteAuthenticated';
import RoutePublic from './components/RoutePublic/RoutePublic';
import { SiteRoute } from './constants';

function App(): ReactElement {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RouteAuthenticated />}>
          <Route path={SiteRoute.Index} element={<Dashboard />}>
            <Route index element={<DeviceTable />} />
            <Route path={SiteRoute.Device} element={<DeviceDetail />} />
            <Route path={SiteRoute.All} element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path={SiteRoute.Login}
          element={
            <RoutePublic>
              <Login />
            </RoutePublic>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
