import { Box, Divider, Stack, Tooltip, Typography } from '@mui/joy';
import React, { ReactElement } from 'react';
import { useHealthCheck } from '../../hooks';

let didInit = false;

function getToolTipText(
  system: string,
  status: string,
  message: string | undefined,
) {
  return status === 'up' ? `${system} is up` : `${system} is down. ${message}`;
}

function Health(): ReactElement {
  const [{ healthcheck }, getHealthCheck] = useHealthCheck();

  React.useEffect(() => {
    if (!didInit) {
      didInit = true;
      getHealthCheck();
    }
  }, []);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" />}
      justifyContent="center"
    >
      {healthcheck?.details &&
        Object.entries(healthcheck.details).map(
          ([system, { status, message }], index) => (
            <Tooltip
              key={index}
              title={getToolTipText(system, status, message)}
              variant="solid"
            >
              <Typography
                startDecorator={
                  <Box
                    component="span"
                    sx={{
                      bgcolor: status === 'up' ? 'success.400' : 'danger.400',
                      width: '0.5em',
                      height: '0.5em',
                      borderRadius: '50%',
                    }}
                  />
                }
              >
                {`${system}`}
              </Typography>
            </Tooltip>
          ),
        )}
    </Stack>
  );
}

export default Health;
