import React from 'react';

function getStorageValue<T>(key: string, initial: T): T {
  const object = localStorage.getItem(key);
  return object !== undefined && object !== null
    ? (JSON.parse(object) as T)
    : initial;
}

export function useLocalStorage<T>(
  key: string,
  initial: T,
): [T, (value: T) => void] {
  const [value, setValue] = React.useState(() => {
    return getStorageValue<T>(key, initial);
  });
  React.useEffect(() => {
    if (value !== undefined && value !== null) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);
  return [value, setValue];
}

// More robust solution
// https://dev.to/link2twenty/react-custom-hook-for-accessing-storage-1e5n
// type StorageType = 'local' | 'session';
// interface Storage {
//   getItem: (key: string) => string | null;
//   setItem: (key: string, value: string) => void;
//   removeItem: (key: string) => void;
//   clear: () => void;
// }

// export function useLocalStorage(type: 'local' | 'session') {
//   const [storageType] = useState<Storage>(window[`${type}Storage`]);
// }
