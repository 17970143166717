import { ApiError } from '.';
import { HealthCheckInterface } from '../../services/api';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

export interface GetHealthCheckState {
  loading: boolean;
  error: ApiError | null;
  healthcheck: HealthCheckInterface | null;
}

const initialState: GetHealthCheckState = {
  loading: false,
  error: null,
  healthcheck: null,
};

const getHealthCheckReducer = (
  state: GetHealthCheckState = initialState,
  action: ApiAction,
): GetHealthCheckState => {
  switch (action.type) {
    case ActionType.HEALTHCHECK_REQUEST_STARTED:
      return { loading: true, error: null, healthcheck: null };
    case ActionType.HEALTHCHECK_REQUEST_SUCCESS:
      return { loading: false, error: null, healthcheck: action.payload };
    case ActionType.HEALTHCHECK_REQUEST_ERROR:
      return { loading: false, error: action.payload, healthcheck: null };
    default:
      return state;
  }
};

export default getHealthCheckReducer;
