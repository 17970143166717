import React from 'react';
import { useAppActions, useAppSelector, useAuthentication } from '.';
import { DeviceSearchRequest, HttpStatus } from '../services/api';
import { GetDeviceSearchState } from '../state';

export const useDeviceSearch = (): [
  GetDeviceSearchState,
  (payload?: DeviceSearchRequest) => void,
] => {
  const { site, setSite } = useAuthentication();
  const { error, ...state } = useAppSelector((state) => state.deviceSearch);
  const { getDeviceSearch } = useAppActions();
  const callAction = (payload?: DeviceSearchRequest) => {
    if (site) {
      getDeviceSearch(site.url, payload);
    }
  };

  React.useEffect(() => {
    if (error?.status === HttpStatus.UNAUTHORIZED) {
      setSite(null);
    }
  }, [error]);

  return [{ error, ...state }, callAction];
};
