import React from 'react';
import {
  AuthenticationContext,
  AuthenticationValues,
} from '../contexts/AuthenticationContext';

export const useAuthentication = (): AuthenticationValues => {
  const authentication = React.useContext(AuthenticationContext);
  return authentication;
};
