import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { SiteRoute } from '../../constants';
import { useAuthentication } from '../../hooks';

function RoutePublic({ children }: { children: ReactElement }): ReactElement {
  const { site } = useAuthentication();
  return site ? <Navigate to={SiteRoute.Index} replace /> : children;
}

export default RoutePublic;
