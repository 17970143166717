import React from 'react';
import { useAppActions, useAppSelector, useAuthentication } from '.';
import { GetHealthCheckState } from '../state';
import { HttpStatus } from '../services/api';

export const useHealthCheck = (): [GetHealthCheckState, () => void] => {
  const { site, setSite } = useAuthentication();
  const { error, ...state } = useAppSelector((state) => state.healthcheck);
  const { getHealthCheck } = useAppActions();
  const callAction = () => {
    if (site) {
      getHealthCheck(site.url);
    }
  };

  React.useEffect(() => {
    if (error?.status === HttpStatus.UNAUTHORIZED) {
      setSite(null);
    }
  }, [error]);

  return [{ error, ...state }, callAction];
};
