import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface DeviceDetailMenuProps {
  name?: string | null;
  img_url?: string | null;
  editName?: () => void;
}

export function DeviceDetailMenu(props: DeviceDetailMenuProps) {
  const { name, img_url, editName } = props;
  const hasName = name !== undefined && name !== null;
  const hasImage = img_url !== undefined && img_url !== null;
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
      >
        <MoreVert />
      </MenuButton>
      <Menu placement="bottom-start">
        <MenuItem disabled={!hasName} onClick={editName}>
          <ListItemDecorator>
            <Edit />
          </ListItemDecorator>{' '}
          Edit Name
        </MenuItem>
        <MenuItem>
          <ListItemDecorator>
            <AccountCircleIcon />
          </ListItemDecorator>{' '}
          {`${hasImage ? 'Change' : 'Add'} Picture`}
        </MenuItem>
        <ListDivider />
        <MenuItem disabled={!hasImage} variant="soft" color="danger">
          <ListItemDecorator sx={{ color: 'inherit' }}>
            <DeleteForever />
          </ListItemDecorator>{' '}
          Delete Picture
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default DeviceDetailMenu;
