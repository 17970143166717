import { AxiosBasicCredentials } from 'axios';
import { Dispatch } from 'react';
import { getApiError } from '.';
import { AppServices } from '../../constants';
import { HttpMethod } from '../../services/api/enums';
import { DeviceSearchRequest } from '../../services/api/interfaces/device-search-request.interface';
import { DeviceSearchInterface } from '../../services/api/interfaces/device-search.interface';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

const path = '/device/search';
const method = HttpMethod.POST;

interface CreateDeviceSearchActionOptions {
  url: string;
  payload?: DeviceSearchRequest;
  auth?: AxiosBasicCredentials;
}

export function createDeviceSearchAction(
  options: CreateDeviceSearchActionOptions,
) {
  return async (dispatch: Dispatch<ApiAction>) => {
    const { url, auth, payload } = options;
    dispatch({ type: ActionType.DEVICE_SEARCH_REQUEST_STARTED });
    try {
      const data = await AppServices.Api.request<
        DeviceSearchInterface,
        DeviceSearchRequest
      >(method, url, path, {}, auth, payload);
      dispatch({
        type: ActionType.DEVICE_SEARCH_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.DEVICE_SEARCH_REQUEST_ERROR,
        payload: getApiError(error),
      });
    }
  };
}

export function getDeviceSearch(url: string, payload?: DeviceSearchRequest) {
  return createDeviceSearchAction({ url, payload });
}
