import { AxiosError } from 'axios';
import { AppMessages } from '../../constants';
import { ApiError } from '../reducers';

export * from './device';
export * from './device-search';
export * from './health-check';

export function getApiError(cause: unknown): ApiError {
  if (cause instanceof AxiosError) {
    const status = cause.response?.status || -1;
    const messageOrMessages =
      cause.response?.data?.message || AppMessages.UnknownError;
    const message = Array.isArray(messageOrMessages)
      ? messageOrMessages.concat(' ')
      : messageOrMessages;
    return { status, message };
  } else {
    const isError = cause instanceof Error;
    const message = isError
      ? cause.message || AppMessages.UnknownError
      : AppMessages.UnknownError;
    const status = -1;
    return {
      status,
      message,
    };
  }
}
