import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthentication } from '../../hooks';
import Login from '../Login/Login';

function RouteAuthenticated(): ReactElement {
  const { site } = useAuthentication();
  return site ? <Outlet /> : <Login />;
}

export default RouteAuthenticated;
