import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import React from 'react';
import { AppColors, AppImages, AppMessages } from '../../constants';
import { formatDeviceType } from '../../helpers';
import { DeviceType } from '../../services/api';

export interface DeviceTableCellNameProps {
  img_url?: string | null;
  name?: string | null;
  firmware?: string | null;
  type: number;
}

function getStartDecorator(type: number) {
  switch (type) {
    case DeviceType.IOS:
      return <AppleIcon style={{ color: AppColors.AppleGrey }} />;
    case DeviceType.ANDROID:
      return <AndroidIcon style={{ color: AppColors.AndroidGreen }} />;
    default:
      return undefined;
  }
}

/**
 * Gets acceptable firmware output string, as backend values
 * can be inconsistent.
 */
function getFirmware(type: number, firmware?: string | null) {
  if (!firmware) {
    return formatDeviceType(type);
  } else {
    switch (type) {
      case DeviceType.IOS:
        if (firmware.includes(AppMessages.iOS)) {
          return firmware;
        } else {
          return `${AppMessages.iOS} ${firmware}`;
        }
      case DeviceType.ANDROID:
        return firmware;
      default:
        return formatDeviceType(type);
    }
  }
}

function DeviceTableCellName(
  props: DeviceTableCellNameProps,
): React.ReactElement {
  const { img_url, name, type, firmware } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <Avatar src={img_url ?? AppImages.DefaultAvatarSrc} />
      <Box sx={{ minWidth: 0 }}>
        <Typography noWrap fontWeight="lg">
          {name ?? AppMessages.Undefined}
        </Typography>
        <Typography
          startDecorator={getStartDecorator(type)}
          noWrap
          level="body-sm"
        >
          {getFirmware(type, firmware)}
        </Typography>
      </Box>
    </Box>
  );
}

export default DeviceTableCellName;
