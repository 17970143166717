import { AxiosBasicCredentials } from 'axios';
import React, { useContext, useState } from 'react';
import { useAppActions, useAppSelector } from '.';
import { Site } from '../constants';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { GetDeviceState } from '../state';

type AuthenticationState = Omit<GetDeviceState, 'device'>;

export const useLogin = (): [
  AuthenticationState,
  (site: Site, auth: AxiosBasicCredentials) => void,
] => {
  const { setSite } = useContext(AuthenticationContext);
  const [authenticatingSite, setAuthenticatingSite] = useState<
    Site | undefined
  >(undefined);
  const { getAuthenticatedDevice } = useAppActions();
  const { device, error, loading } = useAppSelector((state) => state.user);
  const onLogin = (site: Site, auth: AxiosBasicCredentials) => {
    setAuthenticatingSite(site);
    getAuthenticatedDevice(site.url, auth);
  };

  // This should trigger re-render with the AuthenticationContext
  React.useEffect(() => {
    if (device && authenticatingSite) {
      setSite({ device_id: device.id, ...authenticatingSite });
    }
  }, [device, authenticatingSite]);

  return [{ error, loading }, onLogin];
};
