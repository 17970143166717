import { configureStore } from '@reduxjs/toolkit';
import getDeviceReducer from './reducers/getDeviceReducer';
import getDeviceSearchReducer from './reducers/getDeviceSearchReducer';
import getHealthCheckReducer from './reducers/getHealthCheckReducer';

export const store = configureStore({
  reducer: {
    device: getDeviceReducer,
    deviceSearch: getDeviceSearchReducer,
    healthcheck: getHealthCheckReducer,
    user: getDeviceReducer,
  },
});
