import { bindActionCreators } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { actionCreators, AppDispatch, RootState } from '../state';

export * from './useAuthentication';
export * from './useDevice';
export * from './useDeviceSearch';
export * from './useHealthCheck';
export * from './useLogin';
export * from './useLocalStorage';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actionCreators, dispatch);
};
