import React from 'react';
import { useAppActions, useAppSelector, useAuthentication } from '.';
import { HttpStatus } from '../services/api';
import { GetDeviceState } from '../state';

const useDeviceAction = (): [
  GetDeviceState,
  (action: (url: string) => void) => void,
] => {
  const { site, setSite } = useAuthentication();
  const {
    device: data,
    error,
    loading,
  } = useAppSelector((state) => state.device);
  const callAction = (action: (url: string) => void) => {
    if (site) {
      action(site.url);
    }
  };

  React.useEffect(() => {
    if (error?.status === HttpStatus.UNAUTHORIZED) {
      setSite(null);
    }
  }, [error]);

  return [{ device: data, error, loading }, callAction];
};

export const useDevice = (): [GetDeviceState, (id: bigint) => void] => {
  const [state, callAction] = useDeviceAction();
  const { getDevice } = useAppActions();
  const action = (id: bigint) => {
    callAction((url) => getDevice(url, id));
  };
  return [state, action];
};

export const useUpdateDeviceName = (): [
  GetDeviceState,
  (id: bigint, name: string) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { updateName } = useAppActions();
  const action = (id: bigint, name: string) => {
    callAction((url) => updateName(url, id, name));
  };
  return [state, action];
};

export const useUpdateDeviceEmail = (): [
  GetDeviceState,
  (id: bigint, email: string) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { updateEmail } = useAppActions();
  const action = (id: bigint, email: string) => {
    callAction((url) => updateEmail(url, id, email));
  };
  return [state, action];
};

export const useDeleteDeviceEmail = (): [
  GetDeviceState,
  (id: bigint) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { deleteEmail } = useAppActions();
  const action = (id: bigint) => {
    callAction((url) => deleteEmail(url, id));
  };
  return [state, action];
};

export const useUpdateDeviceImei = (): [
  GetDeviceState,
  (id: bigint, imei: string) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { updateImei } = useAppActions();
  const action = (id: bigint, imei: string) => {
    callAction((url) => updateImei(url, id, imei));
  };
  return [state, action];
};

export const useDeleteDeviceImei = (): [
  GetDeviceState,
  (id: bigint) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { deleteImei } = useAppActions();
  const action = (id: bigint) => {
    callAction((url) => deleteImei(url, id));
  };
  return [state, action];
};

export const useUpdateDeviceMsisdn = (): [
  GetDeviceState,
  (id: bigint, msisdn: string) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { updateMsisdn } = useAppActions();
  const action = (id: bigint, msisdn: string) => {
    callAction((url) => updateMsisdn(url, id, msisdn));
  };
  return [state, action];
};

export const useDeleteDeviceMsisdn = (): [
  GetDeviceState,
  (id: bigint) => void,
] => {
  const [state, callAction] = useDeviceAction();
  const { deleteMsisdn } = useAppActions();
  const action = (id: bigint) => {
    callAction((url) => deleteMsisdn(url, id));
  };
  return [state, action];
};
