import { Select, SelectProps } from '@mui/joy';
import { useField } from 'formik';
import { ReactElement } from 'react';

export interface FormikSelectProps extends SelectProps<never, never> {
  name: string;
}

export function FormikSelect(props: FormikSelectProps): ReactElement {
  const { name, children, ...other } = props;
  const [field] = useField(name);
  const onChange = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null,
  ) => {
    field.onChange({ target: { name, value } });
  };

  return (
    <Select value={field.value} onChange={onChange} {...other}>
      {children}
    </Select>
  );
}
