import { ApiError } from '.';
import { DeviceInterface } from '../../services/api';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

export interface GetDeviceState {
  loading: boolean;
  error: ApiError | null;
  device: DeviceInterface | null;
}

const initialState: GetDeviceState = {
  loading: false,
  error: null,
  device: null,
};

const getDeviceReducer = (
  state: GetDeviceState = initialState,
  action: ApiAction,
): GetDeviceState => {
  switch (action.type) {
    case ActionType.DEVICE_REQUEST_STARTED:
      return { loading: true, error: null, device: null };
    case ActionType.DEVICE_REQUEST_SUCCESS:
      return { loading: false, error: null, device: action.payload };
    case ActionType.DEVICE_REQUEST_ERROR:
      return { loading: false, error: action.payload, device: null };
    default:
      return state;
  }
};

export default getDeviceReducer;
