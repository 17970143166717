import { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { getApiError } from '.';
import { AppServices } from '../../constants';
import {
  HealthCheckInterface,
  HttpMethod,
  HttpStatus,
} from '../../services/api';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

const method = HttpMethod.GET;
const path = '/health';

interface CreateHealthCheckActionOptions {
  url: string;
}

export function createHealthCheckAction<T>(
  options: CreateHealthCheckActionOptions,
) {
  const { url } = options;
  return async (dispatch: Dispatch<ApiAction>) => {
    dispatch({ type: ActionType.HEALTHCHECK_REQUEST_STARTED });
    try {
      const data = await AppServices.Api.request<HealthCheckInterface, T>(
        method,
        url,
        path,
        {},
        undefined,
        undefined,
      );
      dispatch({
        type: ActionType.HEALTHCHECK_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status &&
        error.response.data &&
        error.response.status == HttpStatus.SERVICE_UNAVAILABLE
      ) {
        dispatch({
          type: ActionType.HEALTHCHECK_REQUEST_SUCCESS,
          payload: error.response.data as HealthCheckInterface,
        });
      } else {
        dispatch({
          type: ActionType.HEALTHCHECK_REQUEST_ERROR,
          payload: getApiError(error),
        });
      }
    }
  };
}

export function getHealthCheck(url: string) {
  return createHealthCheckAction({ url });
}
