import {
  Add,
  ContentCopyRounded,
  DeleteRounded,
  EditRounded,
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import { ReactNode } from 'react';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';

interface DeviceDetailFieldProps {
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canCopy?: boolean;
  copyText?: string | null;
  name: string;
  skeleton: string;
  loading: boolean;
  content?: ReactNode;
  onAddClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

const iconButtonSx = { '--IconButton-size': '24px' };
const iconSvgSx = {
  width: '18px',
  height: '18px',
};

function DeviceDetailField(props: DeviceDetailFieldProps) {
  const {
    canAdd,
    canEdit,
    canDelete,
    canCopy,
    copyText,
    name,
    skeleton,
    loading,
    content,
    onAddClick,
    onEditClick,
    onDeleteClick,
  } = props;
  const hasContent = !loading && content !== null && content !== undefined;
  const showButtonGroup = hasContent && (canEdit || canDelete || canCopy);

  const onCopyClicked = () => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
    } else if (typeof content === 'string') {
      navigator.clipboard.writeText(content);
    }
  };

  return (
    <Stack>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography level="title-md">{name}</Typography>
        {canAdd && !hasContent && (
          <Button variant="plain" startDecorator={<Add />} onClick={onAddClick}>
            Add
          </Button>
        )}
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TypographySkeleton
          level="body-md"
          loading={loading}
          skeleton={skeleton}
        >
          {content}
        </TypographySkeleton>
        {showButtonGroup && (
          <ButtonGroup disabled={loading} aria-label={`${name} action buttons`}>
            {canEdit && (
              <Tooltip title={`Edit ${name}`}>
                <IconButton
                  aria-label={`edit ${name}`}
                  sx={iconButtonSx}
                  onClick={onEditClick}
                >
                  <EditRounded sx={iconSvgSx} />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title={`Delete ${name}`}>
                <IconButton
                  aria-label={`delete ${name}`}
                  sx={iconButtonSx}
                  onClick={onDeleteClick}
                >
                  <DeleteRounded sx={iconSvgSx} />
                </IconButton>
              </Tooltip>
            )}
            {canCopy && (
              <Tooltip title={`Copy ${name}`}>
                <IconButton
                  aria-label={`copy ${name}`}
                  sx={iconButtonSx}
                  onClick={onCopyClicked}
                >
                  <ContentCopyRounded sx={iconSvgSx} />
                </IconButton>
              </Tooltip>
            )}
          </ButtonGroup>
        )}
      </Stack>
    </Stack>
  );
}

export default DeviceDetailField;
