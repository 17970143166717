import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from '@mui/joy';
import Box from '@mui/joy/Box';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { DeviceInterface, Order } from '../../services/api';

export interface DeviceTableHeadCell {
  id: keyof DeviceInterface;
  label: string;
  width: number | string;
}

export interface DeviceTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceInterface,
  ) => void;
  order: Order;
  orderBy: string;
  cells?: readonly DeviceTableHeadCell[];
}

function DeviceTableHead(props: DeviceTableHeadProps): React.ReactElement {
  const { order, orderBy, cells = [], onRequestSort } = props;
  const createSortHandler =
    (property: keyof DeviceInterface) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr>
        {cells.map((cell) => {
          const active = orderBy === cell.id;
          return (
            <th
              key={cell.id}
              style={{ width: cell.width }}
              aria-sort={
                active
                  ? ({ asc: 'ascending', desc: 'descending' } as const)[order]
                  : undefined
              }
            >
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(cell.id)}
                fontWeight="lg"
                endDecorator={
                  <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                }
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === Order.DESCENDING
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {cell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === Order.DESCENDING
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
        <th
          aria-label="last"
          style={{ width: 'var(--Table-lastColumnWidth)' }}
        />
      </tr>
    </thead>
  );
}

export default DeviceTableHead;
