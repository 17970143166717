import { Warning } from '@mui/icons-material';
import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  ModalProps,
  Stack,
} from '@mui/joy';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { FormikInput } from '../Formik';

export type EditFieldDialogValues = { value: string };

export interface EditFieldDialogProps extends Omit<ModalProps, 'children'> {
  label?: string;
  error?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  isUpdate?: boolean;
  loading?: boolean;
  initialValue: string;
  validationSchema: yup.ObjectSchema<EditFieldDialogValues>;
  onSubmitClicked: (values: EditFieldDialogValues) => void;
}

function EditFieldDialog(props: EditFieldDialogProps) {
  const {
    label,
    error,
    type,
    isUpdate,
    loading,
    initialValue,
    validationSchema,
    onSubmitClicked,
    ...other
  } = props;
  const dialogTitle = `${isUpdate ? 'Update' : 'Add'} ${label}`;
  return (
    <Modal disableRestoreFocus {...other}>
      <ModalDialog>
        <DialogTitle>{dialogTitle}</DialogTitle>
        {error && (
          <DialogContent>
            <Alert color="danger" startDecorator={<Warning />}>
              {error}
            </Alert>
          </DialogContent>
        )}
        <Formik
          initialValues={{ value: initialValue }}
          validationSchema={validationSchema}
          onSubmit={onSubmitClicked}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Stack spacing={2}>
                <FormControl>
                  <FormLabel>{label}</FormLabel>
                  <FormikInput name="value" type={type} autoFocus required />
                </FormControl>
                <Button
                  loading={loading}
                  disabled={!dirty || !isValid}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  );
}

export default EditFieldDialog;
