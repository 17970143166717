import { ApiError } from '.';
import { DeviceSearchInterface } from '../../services/api/interfaces/device-search.interface';
import { ApiAction } from '../actions';
import { ActionType } from '../actions/enums';

export interface GetDeviceSearchState {
  loading: boolean;
  error: ApiError | null;
  result: DeviceSearchInterface | null;
}

const initialState: GetDeviceSearchState = {
  loading: false,
  error: null,
  result: null,
};

const getDeviceSearchReducer = (
  state: GetDeviceSearchState = initialState,
  action: ApiAction,
): GetDeviceSearchState => {
  switch (action.type) {
    case ActionType.DEVICE_SEARCH_REQUEST_STARTED:
      return { loading: true, error: null, result: null };
    case ActionType.DEVICE_SEARCH_REQUEST_SUCCESS:
      return { loading: false, error: null, result: action.payload };
    case ActionType.DEVICE_SEARCH_REQUEST_ERROR:
      return { loading: false, error: action.payload, result: null };
    default:
      return state;
  }
};

export default getDeviceSearchReducer;
