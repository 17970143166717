import { parsePhoneNumber } from 'libphonenumber-js';
import { AppMessages } from '../constants';
import { DeviceType } from '../services/api/enums';

export enum TimeDifferenceModifier {
  DAYS = 24 * 60 * 60 * 1000,
  HOURS = 60 * 60 * 1000,
  MINUTES = 60 * 1000,
}

export function formatMsisdn(msisdn?: string | null): string | null {
  if (msisdn) {
    try {
      return parsePhoneNumber(`+${msisdn}`).formatInternational();
    } catch (error) {
      return msisdn;
    }
  } else {
    return null;
  }
}

export function formatDeviceType(type: number): string {
  switch (type) {
    case DeviceType.ANDROID:
      return AppMessages.Android;
    case DeviceType.ADMIN:
      return 'Admin';
    case DeviceType.INSOLE:
      return 'Caresole';
    case DeviceType.OBD:
      return 'OBD';
    default:
      return AppMessages.iOS;
  }
}

export function getTimeDifference(
  a: Date,
  b: Date,
  modifier: TimeDifferenceModifier,
): number {
  return Math.round(Math.abs((a.getTime() - b.getTime()) / modifier));
}
