import { SiteId } from './enums';
import { Site } from './types';

export abstract class AppMetadata {
  static readonly Production: boolean = process.env.NODE_ENV === 'production';
  static readonly Environment: 'development' | 'production' | 'test' =
    process.env.NODE_ENV;
  static readonly Name: string = `${process.env.REACT_APP_NAME}`;
  static readonly Version: string = `v${process.env.REACT_APP_PACKAGE_VERSION}${
    AppMetadata.Production ? `` : ` (${AppMetadata.Environment})`
  }`;

  static getSiteById(find: SiteId | string): Site {
    const site = this.SiteRecords[find];
    if (site === undefined) {
      throw new Error(`Site with ID "${find} not found`);
    }
    return site;
  }

  static getSites(): Site[] {
    const sites: Site[] = [];
    if (process.env.NODE_ENV !== 'production') {
      sites.push(this.getSiteById(SiteId.LOCAL));
    }
    sites.push(this.getSiteById(SiteId.DEVELOPMENT));
    sites.push(this.getSiteById(SiteId.GUARDIO));
    sites.push(this.getSiteById(SiteId.SAFEKORE));
    return sites;
  }

  private static readonly SiteRecords: Record<SiteId | string, Site> = {
    [SiteId.LOCAL]: {
      id: SiteId.LOCAL,
      name: 'Local',
      url: 'http://localhost:3000',
    },
    [SiteId.DEVELOPMENT]: {
      id: SiteId.DEVELOPMENT,
      name: 'Development',
      url: 'https://development.wetraq.ca',
    },
    [SiteId.GUARDIO]: {
      id: SiteId.GUARDIO,
      name: 'GuardIO',
      url: 'https://guardio.wetraq.ca',
    },
    [SiteId.SAFEKORE]: {
      id: SiteId.SAFEKORE,
      name: 'Safekore',
      url: 'https://safekore.wetraq.ca',
    },
  };
}
