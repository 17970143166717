import { Input, InputProps, Option, Select, Stack } from '@mui/joy';
import React from 'react';
import { formatDeviceType } from '../../helpers';
import { DeviceType } from '../../services/api';

const types = [
  DeviceType.IOS,
  DeviceType.ANDROID,
  DeviceType.INSOLE,
  DeviceType.ADMIN,
];

interface DeviceTableToolbarProps {
  onSearch?: (search: string) => void;
  onDeviceType?: (types: DeviceType[]) => void;
}

type DebounceProps = {
  handleDebounce?: (value: string) => void;
  debounceTimeout: number;
};

function DebounceInput(props: InputProps & DebounceProps) {
  const { handleDebounce, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef<number>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce?.(event.target.value);
    }, debounceTimeout);
  };

  return <Input {...rest} onChange={handleChange} />;
}

function DeviceTableToolbar(
  props: DeviceTableToolbarProps,
): React.ReactElement {
  const { onSearch, onDeviceType } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      pt={1}
      px={1}
    >
      <DebounceInput
        placeholder="Search devices…"
        variant="outlined"
        debounceTimeout={500}
        handleDebounce={onSearch}
      />
      <Select
        defaultValue={[]}
        placeholder="Filter device types…"
        variant="outlined"
        multiple
        sx={{ minWidth: 200 }}
        onChange={(event, values: DeviceType[]) => onDeviceType?.(values)}
      >
        {types.map((value) => (
          <Option key={value} value={value}>
            {formatDeviceType(value)}
          </Option>
        ))}
      </Select>
    </Stack>
  );
}
export default DeviceTableToolbar;
