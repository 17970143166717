import React from 'react';
import { AppDefaults } from '../../../constants';
import { useDeviceSearch, useLocalStorage } from '../../../hooks';
import {
  DeviceInterface,
  DeviceOrderBy,
  DeviceSearchRequest,
} from '../../../services/api';
import { DEFAULT_SORT } from '../constant';

type DeviceTableState = {
  devices: DeviceInterface[];
  loading: boolean;
  count: number;
  pages: number;
  page: number;
  rowsPerPage: number;
  orderBy: DeviceOrderBy;
  search?: string;
  setPayload: (payload: DeviceSearchRequest) => void;
};

export const useDeviceTable = (): DeviceTableState => {
  const [{ result, loading }, getDeviceSearch] = useDeviceSearch();
  const [payload, setPayload] = useLocalStorage<DeviceSearchRequest>(
    'useDeviceTablePayload',
    {
      pagination: {
        items_per_page: AppDefaults.PaginationRows,
        page: AppDefaults.PaginationPage,
      },
      order_by: DEFAULT_SORT,
    },
  );

  React.useEffect(() => {
    if (!loading) {
      getDeviceSearch(payload);
    }
  }, [payload]);

  return {
    devices: result?.items ?? [],
    loading,
    count: result?.items_count ?? 0,
    pages: result?.pages ?? 1,
    page: result?.page ?? AppDefaults.PaginationPage,
    rowsPerPage: result?.items_per_page ?? AppDefaults.PaginationRows,
    orderBy: payload.order_by ?? DEFAULT_SORT,
    setPayload,
  };
};
