import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FormControl, FormLabel, IconButton, Option, Select } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDefaults, SiteRoute } from '../../constants';
import { formatMsisdn } from '../../helpers';
import { DeviceInterface, DeviceType, Order } from '../../services/api';
import DeviceTableCellName from './DeviceTableCellName';
import DeviceTableHead, { DeviceTableHeadCell } from './DeviceTableHead';
import DeviceTableToolbar from './DeviceTableToolbar';
import { useDeviceTable } from './hook';

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from} - ${to} of ${count}`;
}

/**
 * Last column width is 10%, so these should add up to 95%
 */
const headCells: readonly DeviceTableHeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    width: '20%',
  },
  {
    id: 'msisdn',
    label: 'Phone',
    width: '20%',
  },
  {
    id: 'imei',
    label: 'IMEI',
    width: '20%',
  },
  {
    id: 'created_at',
    label: 'Created',
    width: '30%',
  },
];

export function DeviceTable(): React.ReactElement {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [types, setTypes] = React.useState<DeviceType[]>([]);
  const { devices, count, pages, page, rowsPerPage, orderBy, setPayload } =
    useDeviceTable();

  const goToProfile = (id: bigint) =>
    navigate(SiteRoute.Device.replace(':id', `${id}`));

  const handleChangeSearch = (newSearch: string) => {
    setSearch(newSearch);
    setPayload({
      order_by: orderBy,
      pagination: {
        items_per_page: rowsPerPage,
        page: 1,
      },
      include: {
        search: newSearch,
        type: types,
      },
    });
  };

  const handleChangeTypes = (types: DeviceType[]) => {
    setTypes(types);
    setPayload({
      order_by: orderBy,
      pagination: {
        items_per_page: rowsPerPage,
        page: 1,
      },
      include: {
        search,
        type: types,
      },
    });
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceInterface,
  ) => {
    const isAsc =
      orderBy.column === property && orderBy.order === Order.ASCENDING;
    setPayload({
      order_by: {
        column: property,
        order: isAsc ? Order.DESCENDING : Order.ASCENDING,
      },
      pagination: {
        items_per_page: rowsPerPage,
        page,
      },
      include: {
        search,
        type: types,
      },
    });
  };

  const handleChangePage = (newPage: number) => {
    setPayload({
      order_by: orderBy,
      pagination: {
        items_per_page: rowsPerPage,
        page: newPage,
      },
      include: {
        search,
        type: types,
      },
    });
  };

  const handleChangeRowsPerPage = (event: unknown, newValue: number | null) => {
    const newRowsPerPage = newValue ?? AppDefaults.PaginationRows;
    setPayload({
      order_by: orderBy,
      pagination: {
        items_per_page: newRowsPerPage,
        page: 1,
      },
      include: {
        search,
        type: types,
      },
    });
  };

  const getLabelDisplayedRowsFrom = () => {
    if (count < 1) {
      return 0;
    } else if (page === 1) {
      return 1;
    } else {
      return (page - 1) * rowsPerPage;
    }
  };

  const getLabelDisplayedRowsTo = () => {
    if (page === pages) {
      return count;
    } else {
      return devices.length * page;
    }
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        boxShadow: 'sm',
        borderRadius: 'sm',
      }}
    >
      <DeviceTableToolbar
        onSearch={handleChangeSearch}
        onDeviceType={handleChangeTypes}
      />
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        sx={{
          '--TableCell-headBackground': 'transparent',
          '--TableCell-selectedBackground': (theme) =>
            theme.vars.palette.success.softBg,
          '--Table-lastColumnWidth': '10%',
        }}
      >
        <DeviceTableHead
          order={orderBy.order}
          orderBy={orderBy.column}
          cells={headCells}
          onRequestSort={handleRequestSort}
        />
        <tbody>
          {devices.map((device) => (
            <tr key={device.id}>
              <td>
                <DeviceTableCellName {...device} />{' '}
              </td>
              <td>{formatMsisdn(device.msisdn)}</td>
              <td>{device.imei}</td>
              <td>{device.created_at}</td>
              <td>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => goToProfile(device.id)}
                  >
                    View
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Rows per page:</FormLabel>
                  <Select
                    onChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                </FormControl>
                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: getLabelDisplayedRowsFrom(),
                    to: getLabelDisplayedRowsTo(),
                    count,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 1}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === pages}
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}

export default DeviceTable;
