import { Menu } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/joy';
import React, { ReactElement } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SiteRoute } from '../../constants';
import { useAuthentication } from '../../hooks';
import Health from '../Health/Health';

function Dashboard(): ReactElement {
  const navigate = useNavigate();
  const { site, setSite } = useAuthentication();
  const [isDrawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const goToHome = () => navigate(SiteRoute.Index);
  const goToLogin = () => {
    setSite(null);
    navigate(SiteRoute.Login);
  };
  const goToProfile = () =>
    navigate(SiteRoute.Device.replace(':id', `${site?.device_id}`));
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const drawerList = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={goToHome}>Home</ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={goToProfile}>Profile</ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton onClick={goToLogin}>Logout</ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton aria-label="Main menu" onClick={toggleDrawer(true)}>
            <Menu />
          </IconButton>
          <Typography level="title-lg">{process.env.REACT_APP_NAME}</Typography>
        </Stack>
        <Health />
      </Stack>
      <Box
        sx={{
          width: '98%',
          mt: 2,
          mx: 'auto',
        }}
      >
        <Outlet />
      </Box>
      <Drawer size="sm" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </React.Fragment>
  );
}

export default Dashboard;
