export enum ActionType {
  DEVICE_REQUEST_STARTED,
  DEVICE_REQUEST_SUCCESS,
  DEVICE_REQUEST_ERROR,
  DEVICE_SEARCH_REQUEST_STARTED,
  DEVICE_SEARCH_REQUEST_SUCCESS,
  DEVICE_SEARCH_REQUEST_ERROR,
  HEALTHCHECK_REQUEST_STARTED,
  HEALTHCHECK_REQUEST_SUCCESS,
  HEALTHCHECK_REQUEST_ERROR,
}
