import { SvgIcon } from '@mui/joy';
import { type ReactElement } from 'react';
import { ReactComponent as WeTraqIconSvg } from '../../assets/wetraq-icon.svg';
import { type CustomizedIconProps } from './types';

function WeTraqIcon(props: CustomizedIconProps): ReactElement {
  return (
    <SvgIcon
      data-testid="WeTraqIcon"
      component={WeTraqIconSvg}
      inheritViewBox
      {...props}
    />
  );
}

export default WeTraqIcon;
